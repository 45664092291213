import {
  announDetail
} from './api'
import {
  infoImgUrl
} from "@/common/constant.js";
export default {

  name: "index",
  data() {
    return {

      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/announcement",
          name: "announcement",
          title: "公告"
        },
        {
          path: "/announcement/detail",
          name: "announcement/detail",
          title: "公告详情"
        }
      ],
      detailArr: [], //详情数据
      pageLoadFlag: true

    };
  },

  mounted() {
    this.getDetail()


  },
  methods: {
    downFile(item) {
      window.open(infoImgUrl + '/notice/' + item.filePath[0], "_blank");
    },
    getDetail() {
      this.pageLoadFlag = true
      announDetail({
        id: this.$route.query.id
      }).then(res => {
        if (res.data.status == 'login') {
          if (!this.$store.state.user.userInfo.sessionid) {
            this.$router.push('/login')
          }
        }
        this.detailArr = res.data.billboardMessage
        this.pageLoadFlag = false
      }).catch(error => {
        console.log(error)
      })

    },
    goback() {
      if (this.$route.query.name == 'home') {
        this.$router.push({
          path: "/index",
        });
        // window.close()
        return
      } else {
        // 返回公告列表
        this.$router.push({
          path: "/center/announcement",
        });
        return
      }
    }

  }
}